.rodape{
	z-index: 1;
}
.rodape__conteudo{
	background-color: #144681;
	color: #fff;
	padding: 10px 0px;
}
.rodape__logo{
	display: flex;
	align-items: center;
}
.rodape__sociais{
	display: flex;
	list-style: none;
	padding-left: 0;
	margin-bottom: 0;
	margin-left: 110px;
	font-size: 16px;
}
.rodape__sociais__item{
	margin: 5px;
	transition: all 0.3s linear;
	
	&:hover{
		color: #bbb;
		text-decoration: none;
	}
}
.rodape__creditos{
	font-size: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
}
.gv8__box{
	display: flex;
	color: #fff;
	align-items: center;

	&:hover{
		text-decoration: none;
	}
}
.gv8__logo{
	margin-left: 10px;
}
// TABLET PRA BAIXO
@include media-breakpoint-down (md) {
	.gv8{
		justify-content: center;
	}
	.rodape__logo{
		justify-content: center;
		margin-bottom: 10px;
	}
}
// FIM DE TABLE PRA BAIXO