html{
	scroll-behavior: smooth;
}
.sprite-svg-area{
	height: 0;
	overflow: hidden;
}

#app{
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	// transition: filter 0.3s linear;

	.wrapper{
		flex-grow: 1;
		z-index: 2;
	}
}

// .modal-open #app{
// 	filter: blur(2px) saturate(180%);
// }

.owl-carousel:not(.owl-loaded) {
	overflow: hidden;
	white-space: nowrap;

	& > *{
		display: inline-block;
		white-space: normal;
	}

	@include media-breakpoint-up(xl) {
		--item-width: calc(100% / attr(data-xl));

		& > * {
			min-width: var(--item-width);
		}
	}
}
.line__clamp {
	max-height: calc(var(--linhas, 2) * 1.5em);
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: var(--linhas, 2);
}
body{
	font-family: 'montserratregular';
	font-size: 15px;
	line-height: 1.3em;
}
figure, h1, h2, h3, h4, h5, h6{
	margin-bottom: 0px;
}
a{
	color: inherit;

	&:hover{
		color: inherit;
	}
}
.form-control{
	background-color: #151c48;
	border: 2px solid #fff;
	border-radius: 10px;
	padding: 10px 20px 8px 20px;
	height: auto;
	font-size: 15px;
	color: #fff !important;

	&:focus{
		background-color: #151c48;
		outline: none;
		box-shadow: none;
		border-color: #fff;
	}

	&::placeholder{
		color: #fff;
	}
}
.whatsapp__fixo{
	position: fixed;
	right: 20px;
	bottom: 20px;
	z-index: 900;
	width: 55px;
	height: 55px;
	border-radius: 50%;
	transition: all 0.3s linear;

	&:hover{
		box-shadow: 0px 0px 10px rgba(#000, 0.5);
	}
}
.link__grande{
	overflow-wrap: break-word;
	word-wrap: break-word;
	word-break: break-word;
	hyphens: auto;
}
.icone__produto{
	position: absolute;
	bottom: -1px;
	left: 0;
	width: calc(33% + 20px);
	height: auto;
	padding: 10px 3px;
	background-color: #ededed;
	clip-path: polygon(0 0, 76% 0, 100% 99%, 0% 100%);
	padding-right: 20px;
	transition: all 0.3s linear;


	.servicos__moldura{
		margin-bottom: 0;
		width: 100%;
		height: 100%;
		background-color: transparent;
	}
}
